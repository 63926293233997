import { writable, derived } from "svelte/store";
import { faPassport, faAddressCard, faCar } from "@fortawesome/free-solid-svg-icons";

// Get injected environment variables from the document.
export const env = JSON.parse(window.document.head.querySelector('script#env[type="application/json"]').textContent);

// Determine the API origin based on the document's hostname.
export const api = location.hostname.includes('.dev.') ? env.apiAltOrigin : env.apiOrigin;

// Get the verification id from the hash.
export const verificationUuid = location.hash.substring(1);

/**
 * Brand data
 *
 * NOTE: These variables are currently hardcoded for development purposes.
 * 		 In production this data will be obtained from the backend and will be based on the type of verification.
 */
export const brand = writable({
    logo: "images/brands/Knaken/knaken.svg",
    logo_dark: "images/brands/Knaken/knaken_white.svg",
    name: "Knaken",
});

// Main steps - a step has multiple views
export const steps = writable([]);
export const stepIndex = writable(0);
export const step = derived([steps, stepIndex], ([$steps, $stepIndex]) => $steps?.[$stepIndex]);

// Views
export const views = writable([]);
export const viewIndex = writable(0);
export const view = derived([views, viewIndex], ([$views, $viewIndex]) => $views?.[$viewIndex]);

export const stepHasOptions = derived(step, ($step) => 'options' in $step);
export const selectedOption = writable();

// ** Documents **
// Consists of document steps - a document can require multiple images (front, back)
// i.e. multiple iterations over views [Info, Camera, Review]
export const documents = derived(
    [step, stepHasOptions, selectedOption],
    ([$step, $stepHasOptions, $selectedOption]) => {
        if ($stepHasOptions) {
            if ($selectedOption) {
                return $selectedOption.metadata;
            }
        }
        return $step?.metadata;
    });
export const documentIndex = writable(0);
export const document = derived([documents, documentIndex], ([$documents, $documentIndex]) => $documents?.[$documentIndex]);
export const documentRatio = derived((document), ($document) => {
    let type = $document?.key === 'passport' ? $document.key : $document.type;
    return $document?.type in documentRatios && documentRatios?.[type];
});

// Determine if the end state has been reached
export const finalStateCondition = derived(
    [steps, stepIndex, views, viewIndex, documents, documentIndex],
    ([$steps, $stepIndex, $views, $viewIndex, $documents, $documentIndex]) =>
        $stepIndex === ($steps.length - 1)
        && $viewIndex === ($views.length - 1)
        && $documentIndex === ($documents.length - 1)
);

// Camera and capture properties
export const facingMode = derived((document), ($document) => $document?.type === "selfie" ? "user" : "environment");
export const orientation = derived((facingMode), ($facingMode) => $facingMode === "user" ? "scale(-1,1)" : "scale(1,1)");
export const blob = writable({});
export const src = writable("");

// Maps
// -- fontawesome icons
export const idOptionMap = {
    passport: faPassport,
    id_card: faAddressCard,
    drivers_license: faCar
};
// -- Document ratios
// These are derived from the aspect ratios (Wikipedia) of the described documents.
export const documentRatios = {
    card: 0.630607,
    passport: 0.704,
    paper: 1.41421,
    selfie: 1.0,
    wallet_address: 0.33,
    wallet_history: 1.77,
};
