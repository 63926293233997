<script>
    /**
     * This comoponent serves as a basic styled panel element which can be used
     * to display various passed content by means of the slot (below).
     */
    export let title = "No title";
</script>

<div class="panel">
    <h1>{title}</h1>
    <slot />
</div>

<style>
    .panel {
        width: 80%;
        border-radius: 30px;
        padding: 1rem;
        display: flex;
        flex-flow: column nowrap;
        flex: 1;
    }

    .panel h1 {
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 0;
        text-transform: capitalize;
    }

    :global(.panel p, .panel span) {
        font-weight: 400;
        line-height: 1.5;
    }
</style>
