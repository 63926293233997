<script>
	/**
	 * This view is the final step and is shown when all verification steps have been completed.
	 */
	import Header from "../Components/Header.svelte";
	import Error from "../Components/Error.svelte";
	import Panel from "../Components/Panel.svelte";
	import Footer from "../Components/Footer.svelte";

	export let error;

	const title = "Verification request submitted";
	const description = "You will receive an email as soon as your verification has been processed. You can now close this tab.";
</script>

<div id="end" class="view">
	<Header />
	{#if error}
		<Error {error} />
	{/if}
	<Panel {title}>
		<slot>
			<p>{description}</p>
			<div id="checkCircle" />
		</slot>
	</Panel>
	<Footer />
</div>

<style>
	p {
		font-weight: 100;
	}

	#checkCircle {
		width: 150px;
		height: 150px;
		margin: 3rem auto 4rem auto;
		display: flex;
		justify-content: center;
		align-items: center;
		background-image: url(../images/ui/check_circle.svg);
		animation: fadein 0.5s;
	}

	@keyframes fadein {
		0% {
			opacity: 0;
			transform: scale(1, 1) rotate(-175deg);
		}
		65% {
			transform: scale(1.5, 1.5);
		}
		100% {
			opacity: 1;
			transform: scale(1, 1) rotate(0deg);
		}
	}
</style>
