<script>
    /**
     * This component displays the partnered company's name or logo;
    */
    import { brand } from "../stores.js";

    export let shadow = false;
    export let mode = "light";
    
    $: logo = mode === "light" ? $brand.logo : $brand.logo_dark;
</script>

<header class="{shadow ? 'shadow' : ''}">
    <div class="row">
        <small>Verification for</small>&nbsp;
        <img src="{logo}" alt="{$brand.name}">
    </div>
</header>

<style>
    header {
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 9999;
        background:transparent;
    }

    .row {
        align-items: center;
        height: 40px;
    }

    small {
        position: absolute;
        font-weight: 100;
        top: .3rem;
        left: 5.5rem;
        color: gainsboro;
        font-size: .75rem
    }

    img {
        height: 30px;
        /* margin: auto; */
    }

    .shadow {
        box-shadow: inset 0 -2rem 2rem -2rem gainsboro;
    }
</style>