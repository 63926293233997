import { api, verificationUuid } from "./stores";

// Set manipulation
export const union = (a, b) => [...new Set([...a, ...b])];
export const intersection = (a, b) => a.filter(x => b.includes(x));
export const difference = (a, b) => a.filter(x => !b.includes(x)).concat(b.filter(x => !a.includes(x)));

// String manipulation
export const capitalize = (word) => word[0].toUpperCase() + word.slice(1);

// API interaction
export function upload(type, payload, url = api + "/upload") {
    const data = new FormData();
    data.append("type", type);

    // Append files to formdata independently.
    if ((type === "file" || type === "burst") && !!payload.file) {
        Object.entries(payload.file).forEach(([key, value]) => {
            data.append(key, value);
        });
        // Remove files from the payload.
        delete payload.file;
    }

    // JSON encode the rest of the payload.
    if (!!payload) {
        const json = JSON.stringify(payload);
        data.append("data", json);
    }

    return fetch(
        url,
        {
            method: "POST",
            // mode: 'no-cors',
            body: data,
            headers: new Headers({
                "X-CURRENT-VERIFICATION": verificationUuid,
            }),
        })
        .then(response => response.json());
};

export const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
