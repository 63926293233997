<script>
    /**
     * This component displays a simple loading text with 3 dot animation.
     *
     * When loading has finished the content of the passed slot is displayed.
     */
    export let loading = true;
    export let text = "Loading";
    export let opacity = 1;
</script>

{#if loading}
    <div id="loader" style="background-color: rgba(0, 0, 0, {opacity});">
        <h1 class="loading animate-dots">{text}<span /></h1>
    </div>
{:else}
    <slot />
{/if}

<style>
    div {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        color: white;
        z-index: 999;
    }

    .loading {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        text-align: center;
        font-weight: 100;
        margin: 0;
    }

    .animate-dots span:before {
        content: ".";
        display: inline-block;
        text-align: start;
        width: 30px;
        animation: dots 3s linear infinite;
        position: relative;
    }

    @keyframes dots {
        0% {
            content: ".  ";
        }
        33% {
            content: ".. ";
        }
        66% {
            content: "...";
        }
        99% {
            content: "   ";
        }
    }
</style>
