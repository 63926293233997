<script lang="ts">
	import { onMount } from "svelte";
	import { env, api, steps, views, view, verificationUuid } from "./stores";
	import Splash from "./Views/Splash.svelte";
	import Location from "./Views/Location.svelte";
	import Info from "./Views/Info.svelte";
	import Camera from "./Views/Camera.svelte";
	import Review from "./Views/Review.svelte";
	import * as Sentry from "@sentry/browser";
	import { BrowserTracing } from "@sentry/tracing";
	import { bankCardStep } from "./testData";

	const sentryConfig = env.sentry;
	// Initialise Sentry but only if we're actually sampling anything.
	sentryConfig.sampleRate + sentryConfig.tracesSampleRate > 0 && Sentry.init(Object.assign({
		integrations: [new BrowserTracing()],
		environment: location.host,
	}, sentryConfig));

	// Treat hash change as url change.
	addEventListener("hashchange", location.reload.bind(location));

	// Defined here instead of in stores.js
	// because importing the views causes circular dependencies
	$views = [Splash, Location, Info, Camera, Review];

	// from https://gist.github.com/johnelliott/cf77003f72f889abbc3f32785fa3df8d
	const uuid4RegExp = new RegExp(
		/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
	);

	let loading, error;

	onMount(async () => {
		if (verificationUuid === "TEST") {
			$steps = [ bankCardStep ];
		} else {
			if (!uuid4RegExp.test(verificationUuid)) {
				error = "Invalid verification id";
				return;
			}
			const response = await fetch(`${api}/flow/${verificationUuid}`, {
				headers: { "X-CURRENT-VERIFICATION": verificationUuid },
			});
			$steps = await response.json();
		}
		if (!$steps.length) {
			error = "Invalid verification";
		}
		loading = false;
	});
</script>

<svelte:body />
<svelte:component this={$view} {loading} {error} />

<style>
	:global(.view) {
		width: 100%;
		height: 100%;
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
	}

	:global(button) {
		cursor: pointer;
	}
</style>
