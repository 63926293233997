<script>
	/**
	 * This view displays information to the user about the next step.
	 *
	 * If applicable, the user is presented with a number of options to pick from.
	 */
	import Header from "../Components/Header.svelte";
	import Error from "../Components/Error.svelte";
	import Panel from "../Components/Panel.svelte";
	import Footer from "../Components/Footer.svelte";
	import { views } from "../stores";
	import { upload, isSafari } from "../globals";

	import Fa from "svelte-fa";
	import {
		faChevronRight,
		faExternalLinkAlt,
		faLocationArrow,
		faMapMarkerAlt,
	} from "@fortawesome/free-solid-svg-icons";

	export let loading = false;
	export let error;

	const title = "Location";
	const description =
		"For a successful verification we would like to verify your \
		location once. Grant permission in order to to continue. Make \
		sure the location services (GPS) of your device are turned on.";

	let skip = false,
		locationError = false;

	async function uploadLocation(position) {
		let response = await upload("location", {
			location: {
				coords: {
					accuracy: position.coords.accuracy,
					altitude: position.coords.altitude,
					altitudeAccuracy: position.coords.altitudeAccuracy,
					heading: position.coords.heading,
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
					speed: position.coords.speed,
				},
				timestamp: position.timestamp,
			},
		});
		loading = false;
		goToNextView();
	}

	function getCurrentPositionError(error) {
		console.log(error.message);
		alert("Turn on the location services (GPS) of your device to continue.");
		loading = false;
		locationError = true;
	}

	function goToNextView() {
		skip = true;

		if (!loading) {
			// Remove location view from view list
			$views = $views.slice(1);
		}
	}

	function getLocation(event) {
		if (!navigator.geolocation) {
			loading = false;
			locationError = true;
			alert("Geolocation API not supported.");
		} else {
			loading = true;
			navigator.geolocation.getCurrentPosition(
				uploadLocation,
				getCurrentPositionError,
				{
					enableHighAccuracy: true,
					timeout: 5000,
					maximumAge: 0
				}
			);
		}
	}
</script>

<div id="location" class="view">
	<Header />
	{#if error}
		<Error {error} />
	{/if}
	<Panel {title}>
		<slot>
			<p>{description}</p>
			{#key loading}
				<button
					class:locating={loading}
					type="button"
					on:click|once={getLocation}
					disabled={loading || skip}
				>
					{#if isSafari}
						<Fa
							icon={faLocationArrow}
							pull="left"
							class="mr-1"
							translateY={0.1}
						/>
					{:else}
						<Fa
							icon={faMapMarkerAlt}
							pull="left"
							class="mr-1"
							translateY={0.1}
						/>
					{/if}
					Share location
					{#key loading}
						{#if !loading}
							<Fa
								icon={faChevronRight}
								pull="right"
								translateY={0.1}
							/>
						{/if}
					{/key}
				</button>
			{/key}
			{#if locationError}
				<p id="locationOptOut" on:click|once={goToNextView}>
					I prefer not to
				</p>
			{/if}
			<span>
				Do you need help turning on the location services of your
				device? Support links:
				<br />
				<Fa
					icon={faExternalLinkAlt}
					pull="left"
					class="mr-05"
					translateY={0.2}
				/>
				<a
					href="https://support.apple.com/en-us/HT207092"
					target="_blank"
					rel="noopener noreferrer nofollow"
				>
					iOS
				</a>
				|
				<a
					href="https://support.google.com/accounts/answer/3467281?hl=en"
					target="_blank"
					rel="noopener noreferrer nofollow"
				>
					Android
				</a>
			</span>
		</slot>
	</Panel>
	<Footer />
</div>

<style>
	button {
		padding: 1.2rem;
		border: 3px solid black;
		background-color: white;
		border-radius: 10px;
		text-align: left;
		font-size: 1.1rem;
		font-weight: 500;
		margin-top: 0.75rem;
		margin-bottom: 0.25rem;
		cursor: pointer;
		overflow: hidden;
	}

	button:hover,
	button:active,
	button.locating {
		background-color: black;
		color: white;
	}

	button.locating:after {
		content: "";
		display: block;
		height: 1.25rem;
		width: 1.25rem;
		float: right;
		background-image: url(../favicon.png);
		background-size: contain;
		background-repeat: no-repeat;
		animation: fadeinout 2s linear infinite;
		opacity: 0;
	}

	:global(button.locating > i) {
		filter: invert(0.75);
	}

	#locationOptOut {
		visibility: visible;
		color: #ddd;
		text-decoration: underline;
		cursor: pointer;
		margin-left: 0.6rem;
		margin-top: 0;
		width: fit-content;
		line-height: 2;
	}

	span {
		margin-top: auto;
		vertical-align: baseline;
	}

	a {
		text-decoration: underline;
	}

	@keyframes fadeinout {
		50% {
			opacity: 1;
		}
	}
</style>
