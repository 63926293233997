<script>
    /**
     * This component is a visual aid for the user when the Camera view is active.
     *
     * It displays a rectangular view finder which should match or approximate the
     * form of the document that is supposed to be captured. Alternatively, an
     * egg-shaped cutout is displayed whenever a selfie should be taken or for a
     * liveness check.
     */
    import { onMount } from "svelte";
    import { facingMode, documentRatio } from "../stores";

    export let capturing = false;

    let aperture, width, height;

    function resizeApertureHeight() {
        // Documents (office paper) have a different orientation
        // and size compared to ID and bank cards.
        aperture.style.height = `${width * $documentRatio}px`;
    }
    function resizeApertureWidth() {
        aperture.style.width = `${height / $documentRatio}px`;
    }

    // Resize the view finder opening when using the rear camera.
    onMount(() => $facingMode === "environment" && resizeApertureHeight());
</script>

{#if $facingMode === "environment"}
    <!-- When the rear camera is active -->
    <div
        id="aperture"
        class:capturing
        bind:this={aperture}
        bind:offsetWidth={width}
        bind:offsetHeight={height}
    >
        <span id="aperture__bottom" />
    </div>
{:else}
    <!-- When the front/selfie camera is active -->
    <div id="aperture-face" />
{/if}

<style>
    #aperture-face {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url(../images/ui/face_hole.svg);
        background-position: center;
    }

    #aperture {
        --borderWidth: 4px;
        --negBorderWidth: calc(-1.5 * var(--borderWidth));
        --borderRadius: 20px;

        box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.7);
        justify-content: center;
        flex-direction: column;
        align-items: center;
        display: flex;
        height: 200px;
        width: 86%;
        z-index: 3;

        /* FANCY CORNERS */
        position: relative;
        background: none;
        border: none;
        color: white;
        box-sizing: content-box;
        border: var(--borderWidth) solid transparent;
        border-radius: 20px;
    }

    #aperture::before,
    #aperture::after,
    #aperture__bottom::before,
    #aperture__bottom::after {
        display: block;
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        z-index: 3;
    }

    #aperture::before {
        top: var(--negBorderWidth);
        left: var(--negBorderWidth);
        border-top: var(--borderWidth) solid white;
        border-left: var(--borderWidth) solid white;
        transition: 0.3s all;
        border-top-left-radius: var(--borderRadius);
    }

    #aperture::after {
        top: var(--negBorderWidth);
        right: var(--negBorderWidth);
        border-top: var(--borderWidth) solid white;
        border-right: var(--borderWidth) solid white;
        transition: 0.3s all;
        border-top-right-radius: var(--borderRadius);
    }

    #aperture__bottom::before {
        bottom: var(--negBorderWidth);
        left: var(--negBorderWidth);
        border-bottom: var(--borderWidth) solid white;
        border-left: var(--borderWidth) solid white;
        transition: 0.3s all;
        border-bottom-left-radius: var(--borderRadius);
    }

    #aperture__bottom::after {
        bottom: var(--negBorderWidth);
        right: var(--negBorderWidth);
        border-bottom: var(--borderWidth) solid white;
        border-right: var(--borderWidth) solid white;
        transition: 0.3s all;
        border-bottom-right-radius: var(--borderRadius);
    }

    #aperture.capturing::before,
    #aperture.capturing:after {
        width: 50px;
        height: 50px;
    }

    #aperture.capturing #aperture__bottom::before,
    #aperture.capturing #aperture__bottom::after {
        width: 50px;
        height: 50px;
    }
</style>
