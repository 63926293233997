<script>
	/**
	 * This view is is used to take pictures and video of documents or the user.
	 */
	import { onMount, onDestroy } from "svelte";
	import {
		document,
		viewIndex,
		blob,
		src,
		facingMode,
		orientation,
	} from "../stores";
	import { isSafari } from "../globals";
	import Aperture from "../Components/Aperture.svelte";
	import Loading from "../Components/Loading.svelte";
	import Header from "../Components/Header.svelte";
	import Footer from "../Components/Footer.svelte";
	import Fa from "svelte-fa";
	import { faInfoCircle, faRedoAlt } from "@fortawesome/free-solid-svg-icons";

	export let loading;
	export let error = false;

	// NOTE: backend only supports jpeg at the moment
	const imageMime = "image/jpeg";
	const imageQuality = 0.88;

	// Error messages
	const cameraError =
		"No camera found or camera access denied.<br/>Please allow camera access or try a different browser or device.";

	// NOTE: check why aperture is defined here and whether it needs to be passed to the Aperture component.
	let video, aperture, canvas;
	let disabled = false;
	let capturing = false;

	$: loading = !((video && video.srcObject) || error);

	onMount(async () => {
		// Mirror video when using the front cam
		video.style.transform = $orientation;
		const mediaOptions = {
			audio: false,
			video: {
				facingMode: $facingMode,
				aspectRatio: { ideal: 1.33333333 },
				// setting an ideal width value combined with the other settings makes getUserMedia fail on certain devices.
				height: { ideal: 1920 },
				resizeMode: "none", // can be none or crop-and-scale
			},
		};

		// Initialize video stream
			stopMedia();
			try {
				video.srcObject = await navigator.mediaDevices.getUserMedia(mediaOptions);
				video.play();
			} catch(_) {
				error = true;
			}
	});

	onDestroy(stopMedia);

	function stopMedia() {
		video?.srcObject?.getTracks().forEach(track => track.stop());
	}

	function captureHandler() {
		disabled = true;
		capturing = true;
		setTimeout(() => {
			capturing = false;
		}, 400);

		// Take a picture
		canvas.width = video.videoWidth;
		canvas.height = video.videoHeight;
		canvas
			.getContext("2d")
			.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
		canvas.toBlob(
			(b) => {
				$blob = b;
				$src = URL.createObjectURL(b);
			},
			imageMime,
			imageQuality
		);

		setTimeout(() => {
			stopMedia();
			$viewIndex++;
		}, 500);
	}

	function iosSafariHack(e) {
		isSafari && window.setTimeout((_) => video.play(), 100);
	}
</script>

<svelte:window on:orientationchange={iosSafariHack} />

<div id="camera" class="view">
	<Header mode="dark" shadow={false} />

	{#if error}
		<div class="error-container">
			<p class="error">
				<Fa
					icon={faInfoCircle}
					translateY={0.1}
					style="margin-bottom: .5em"
				/>
				<br />
				{@html cameraError}
				<br />
				<br />
				<button
					type="button"
					class="back"
					on:click|once={() => location.reload()}
				>
					<Fa
						icon={faRedoAlt}
						pull="left"
						flip="horizontal"
						translateY={0.1}
					/>&nbsp; Start over
				</button>
			</p>
		</div>
	{:else}
		<!-- svelte-ignore a11y-media-has-caption -->
		<video bind:this={video} autoplay playsinline muted />
		<canvas bind:this={canvas} />

		<Loading {loading}>
			<p class="hint">{$document.hint}</p>
			<div id="overlay">
				<Aperture bind:this={aperture} bind:capturing />
			</div>

			<button
				type="button"
				class="capture"
				on:click|once={captureHandler}
				{disabled}
			/>
		</Loading>
	{/if}

	<!-- <Footer shadow={false} /> -->
</div>

<style>
	.error-container {
		height: 100%;
		width: 100%;
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		background: black;
	}

	.error {
		color: red;
		font-weight: 300;
		text-align: center;
	}

	.hint {
		position: relative;
		z-index: 4;
		top: 0;
		color: white;
		text-align: center;
		padding: 0 1rem;
		font-weight: 400;
		margin-top: 0;
	}

	canvas {
		display: none;
	}

	button.capture {
		position: absolute;
		z-index: 4;
		bottom: 20px;
		/* bottom: calc(2rem + 10px + 100px); */

		background-color: white;
		border-radius: 50%;
		border: 2px solid gainsboro !important;
		width: 50px;
		height: 50px;
		border: 0px;
	}

	button.capture:active {
		background-color: white;
		transform: translateY(2px);
	}

	button.capture:after {
		content: "";
		display: block;
		z-index: 1;
		border-radius: 50%;
		border: 10px solid white;
		width: 51px;
		height: 50px;
		transform: translate(-19px, -16px);
		pointer-events: all;
	}

	button.capture:active:after {
		transform: translate(-19px, -18px);
	}

	button.back {
		border: 2px solid red;
		border-radius: 10px;
		padding: 0.5rem 1rem;
		background: transparent;
		color: red;
	}

	video {
		position: absolute;
		top: 0;
		z-index: 0;
		display: block;
		height: 100%;
		/* transform: translateX(-50%); */
	}

	#camera {
		overflow: hidden;
	}

	#overlay {
		justify-content: center;
		flex-direction: column;
		align-items: center;
		position: absolute;
		overflow: hidden;
		display: flex;
		bottom: -1em;
		right: 0;
		left: 0;
		top: 0;
		z-index: 2;
		text-align: center;
	}
</style>
