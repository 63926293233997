export const bankCardStep = {
	type: "bank_card",
	name: "Bank Card",
	description: "In the next step, you have to take a picture of your bank card. Your name, account number, expiration date should be visible.",
	metadata: [
		{
			key: "bank_card",
			name: "Bank Card",
			hint: "Take a picture of your bank card.",
			type: "card",
			uploaded: false
		},
	]
};
