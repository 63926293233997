<script>
    /**
     * This view functions as a control step in which the user can review the picture
     * or video that was taken. The user will be presented with the option to redo the
     * action or confirm, which will trigger a file upload.
     *
     * Image review supports zoom and pan.
     */
    import {
        views,
        viewIndex,
        documents,
        documentIndex,
        document,
        documentRatio,
        stepIndex,
        finalStateCondition,
        blob,
        src,
        facingMode,
    } from "../stores";
    import { upload } from "../globals";
    import { get } from "svelte/store";
    import Loading from "../Components/Loading.svelte";
    import Header from "../Components/Header.svelte";
    import Error from "../Components/Error.svelte";
    import Footer from "../Components/Footer.svelte";
    import End from "../Views/End.svelte";
    // FontAwesome (icons)
    import Fa from "svelte-fa";
    import { faUndoAlt } from "@fortawesome/free-solid-svg-icons";
    import { faArrowAltCircleUp } from "@fortawesome/free-regular-svg-icons";
    // Swiper (image zoom)
    import { Swiper, SwiperSlide } from "swiper/svelte";
    import SwiperCore, { Zoom } from "swiper";
    import { onDestroy, onMount } from "svelte";

    export let loading;
    export let error;

    // Swiper setup
    SwiperCore.use([Zoom]);

    let frame, width, height, swiperHeight, uploading;

    // Button handlers
    const redoHandler = () => {
        $viewIndex--;
    };
    const confirmHandler = async () => {
        uploading = true;
        let payload = { file: { [$document.key]: get(blob) } };

        const response = await upload("file", payload);
        const currentDocument = $documentIndex;
        const lastDocument = $documents.length - 1;

        if (response?.status) {
            if (currentDocument < lastDocument) {
                // SAME STEP, but new partial document - so increment doc index
                $documentIndex++;
                // And reset view index - to loop over camera & review views
                $viewIndex = 1;
            } else if (currentDocument === lastDocument) {
                if ($finalStateCondition && response.status === "completed") {
                    // FINISHED ALL STEPS - append end view and go there
                    $views = [...$views, End];
                    $viewIndex++;
                } else {
                    // NEXT STEP
                    // Reset view & document indices
                    $viewIndex = 0;
                    $documentIndex = 0;
                    // NOTE: Step index increment should be last!!
                    $stepIndex++;
                }
            } else {
                // NEXT DOCUMENT
                $documentIndex++;
            }
        }
        uploading = false;
    };
    const resizeFrameHeight = () => (frame.style.height = `${frameHeight}px`);
    const resizeFrameWidth = () => (frame.style.width = `${frameWidth}px`);
    const zoomHint = "Double tap or pinch to zoom.";

    $: reviewHint =
        $document.type === "selfie"
            ? "Check if your face is clearly visible."
            : "Check if everything is readable.";
    $: loading = !$src; // Stop loading if src is loaded
    $: frameHeight = width * $documentRatio;
    $: frameWidth = height / $documentRatio;
    $: isSelfieMode = $facingMode === "user";
    $: horizontalImageOffset = isSelfieMode ? "7%" : "-7%";
    $: verticalImageOffset = `${(frameHeight - swiperHeight) / 2}px`;

    onMount(resizeFrameHeight);
    onDestroy(() => ($src = ""));
</script>

<div id="review" class="view">
    <Header />
    {#if error}
        <Error {error} />
    {/if}
    <div class="review-hint">
        <p>{reviewHint}</p>
        <p>{zoomHint}</p>
    </div>
    <div id="reviewContent">
        <div
            id="frame"
            bind:this={frame}
            bind:offsetWidth={width}
            bind:offsetHeight={height}
            style="width:{isSelfieMode ? '78%' : '86%'};height:{frameHeight}px;"
        >
            <div
                id="swiperWrapper"
                bind:offsetHeight={swiperHeight}
                style="width:100{loading ? '%' : 'vw'};"
            >
                <Loading {loading} text="Processing">
                    <!-- Zoom/pan image element -->
                    <Swiper
                        zoom={true}
                        style="transform:translate(-7%, {verticalImageOffset});"
                        passiveListeners={false}
                    >
                        <SwiperSlide>
                            <div class="swiper-zoom-container">
                                {#key $src}
                                    <img src={$src} alt="Review" />
                                {/key}
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </Loading>
            </div>
            {#if uploading}
                <Loading loading={uploading} text="Uploading" opacity={0.5} />
            {/if}
        </div>
    </div>
    <div id="actions">
        <!-- svelte-ignore a11y-mouse-events-have-key-events -->
        <button id="redo" type="button" on:click|once={redoHandler}>
            <Fa icon={faUndoAlt} class="rotate" />
            &nbsp;&nbsp;Redo
        </button>
        <!-- svelte-ignore a11y-mouse-events-have-key-events -->
        <button
            id="confirm"
            type="button"
            class:loading
            on:click|once={confirmHandler}
        >
            <Fa icon={faArrowAltCircleUp} class="move-up" />
            &nbsp;&nbsp;Confirm
        </button>
    </div>
    <Footer />
</div>

<style>
    #swiperWrapper {
        min-height: 100%;
        height: auto;
    }
    .review-hint {
        position: relative;
        text-align: center;
    }

    .review-hint > p:first-of-type {
        font-weight: 500;
        margin-top: calc(100vh / 800);
    }
    .review-hint > p:nth-of-type(2) {
        font-weight: 300;
        margin-top: -0.75em;
    }

    #reviewContent {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
    }

    #frame {
        border-radius: 10px;
        border: 3px solid black;
        background: black;
        overflow: hidden;
    }

    img {
        width: 100vw;
        display: block;
        background-color: black;
    }

    :global(.swiper-slide) {
        overflow: hidden;
    }

    #actions {
        display: flex;
        /* width: calc(100% - 6rem); */
        width: calc(86% + 6px);
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 35px;
    }

    #redo,
    #confirm {
        padding: 0.5rem 1rem;
        color: white;
        flex: 1 1 40%;
        height: 60px;
        font-size: 1rem;
        font-weight: 500;
        border-radius: 10px;
        border: 3px solid black;
    }

    #redo {
        color: black;
        background-color: white;
        margin-right: 2rem;
    }

    #confirm {
        background-color: black;
        color: white;
    }

    #confirm.loading {
        pointer-events: none;
    }

    :hover > :global(.rotate) {
        animation: rotate_counter_fade 1.3s ease-in infinite;
    }

    :hover > :global(.move-up) {
        animation: move_up_fade 1s ease-out infinite;
    }

    @keyframes move_up_fade {
        from {
            transform: translateY(0);
            opacity: 1;
        }

        to {
            transform: translateY(-0.75rem);
            opacity: 0;
        }
    }

    @keyframes rotate_counter_fade {
        0% {
            transform: rotate(0deg);
            opacity: 1;
        }

        90%,
        100% {
            transform: rotate(-350deg);
            opacity: 0.1;
        }
    }
</style>
