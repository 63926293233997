<script>
    /**
     * This component displays the Verifire logo and slogan.
    */
    export let shadow = false;
</script>

<footer class="panel {shadow ? 'shadow' : ''}">
    <img id="logo" src="images/logos/verifire_logo_flame_lightgray.png" alt="logo">
    <p>| Identity lit up</p>
</footer>

<style>
    footer {
        height: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: auto;
        position: relative;
        z-index: 9999;
        background:transparent;
    }

    footer > img {
        height: 20px;
        margin-bottom: 5px;
    }

    footer > p {
        color: #d9dbe0;
        line-height: 2;
        font-weight: 300;
        font-size: 11px;
    }

    .shadow {
        box-shadow: inset 0 2rem 2rem -2rem gainsboro;
    }
</style>
