<script>
    /**
     * This component displays error messages;
     */
    export let error;
</script>

<div id="error">
    <p>{error}</p>
</div>

<style>
    #error {
        height: 2rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: darkred;
        background-color: lightcoral;
    }
</style>
