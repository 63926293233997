<script>
	/**
	 * This view displays information to the user about the next step.
	 *
	 * If applicable, the user is presented with a number of options to pick from.
	 */
	import Header from "../Components/Header.svelte";
	import Error from "../Components/Error.svelte";
	import Panel from "../Components/Panel.svelte";
	import Footer from "../Components/Footer.svelte";
	import { step, viewIndex, selectedOption, idOptionMap } from "../stores";
	import Fa from "svelte-fa";
	import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
	import { get } from 'svelte/store'

	export let error;

	function openCamera(choice = null) {
		$viewIndex++;
		$selectedOption = choice;
	}
</script>

<div id="info" class="view">
	<Header />
	{#if error}
		<Error {error} />
	{/if}
	<Panel title={$step.name || $step.type}>
		<slot>
			<p>{@html $step.description}</p>
			{#if "options" in $step}
				{#each Object.entries($step.options) as [key, option]}
					<button type="button" on:click|once={openCamera(option)}>
						<Fa
							icon={idOptionMap[key]}
							pull="left"
							translateY={0.1}
							class="mr-1 sq-1"
						/>
						{option.name}
						<Fa
							icon={faChevronRight}
							pull="right"
							translateY={0.1}
						/>
					</button>
				{/each}
			{:else}
				<button type="button" on:click|once={openCamera}>
					Continue
					<Fa icon={faChevronRight} pull="right" translateY={0.1} />
				</button>
			{/if}
		</slot>
	</Panel>
	<Footer />
</div>

<style>
	button {
		padding: 1.2rem;
		border: 3px solid black;
		background-color: white;
		border-radius: 10px;
		text-align: left;
		font-size: 1.1rem;
		font-weight: 500;
		margin-top: 0.75rem;
		cursor: pointer;
	}

	button:hover,
	button:active {
		background-color: black;
		color: white;
	}
</style>
