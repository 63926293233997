<script>
	/**
	 * This view is the splash screen a.k.a. landing "page". It displays the logo
	 * and instructions to start the verification, accompanied by some inviting animations.
	 */
	import { views } from "../stores.js";
	import { fade } from "svelte/transition";
	import * as easing from "svelte/easing";

	/**
	 * note w.r.t. click callback:
	 * $views.shift() won't work here
	 * as in-place array mutation will NOT trigger re-render
	 */
	export let loading = true;
	export let error;

	const start = () => $views = $views.slice(1);
	const logo1 = "images/logos/verifire_logo_accent1.svg";
	const logo2 = "images/logos/verifire_logo_accent2.svg";

	$: message = loading ? "Loading" : "Touch to start";
</script>

<div id="splash" class="view" class:loading on:click|once={start}>
	<img src={logo1} alt="logo1" />
	<img src={logo2} alt="logo2" class="top" />
	{#if error}
		<div>
			<p class:error>{error}</p>
		</div>
	{:else}
		<div>
			{#key loading}
				<p
					class:loading
					in:fade={{ duration: 1000, easing: easing.cubicInOut }}
				>
					{message}
				</p>
			{/key}
			<div class="ripple" />
		</div>
	{/if}
</div>

<style>
	#splash {
		background-color: black;
		color: white;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
		position: relative;
		box-sizing: border-box;
		padding: 0 20vw;
	}

	#splash.loading {
		pointer-events: none;
	}

	#splash > img,
	#splash > img.top {
		box-sizing: border-box;
		width: 100%;
		flex: none;
	}

	#splash > img.top {
		margin-left: -100%;
		animation-name: crossfade;
		animation-timing-function: ease-out;
		animation-iteration-count: infinite;
		animation-duration: 1s;
		animation-direction: alternate;
	}

	#splash > div > p {
		color: white;
		position: relative;
		align-items: center;
		font-size: 1.17em;
		font-weight: 400;
	}

	#splash > div > p.loading {
		animation: glow 1.5s ease-in-out infinite alternate;
	}

	@keyframes glow {
		from {
			opacity: 0.3;
		}

		to {
			opacity: 0.7;
		}
	}

	#splash > div > p:not(.loading):before {
		content: "";
		display: block;
		background-image: url(../images/ui/touch_white.svg);
		background-repeat: no-repeat;
		background-size: contain;
		height: 48px;
		width: 48px;
		position: absolute;
		left: 50%;
		transform: translate(-50%, -120%);
	}

	.error {
		color: red;
	}

	.ripple {
		position: absolute;
		left: 50%;
		transform: translate(-58%, -580%);
		width: 1rem;
		height: 1rem;
		background-color: transparent;
		border-radius: 50%;
	}

	.ripple::before,
	.ripple::after {
		content: "";
		display: block;
		position: absolute;
		border: 0.15rem solid rgba(255, 255, 255, 0.7);
		left: -20px;
		right: -20px;
		top: -20px;
		bottom: -20px;
		border-radius: 50%;
		animation: ripple 1.5s linear infinite;
		backface-visibility: hidden;
	}

	.ripple::after {
		animation-delay: 0.5s;
	}

	@keyframes ripple {
		0% {
			transform: scale(0.5);
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
		100% {
			transform: scale(1.2);
			opacity: 0;
		}
	}

	@keyframes crossfade {
		0% {
			opacity: 1;
		}
		33% {
			opacity: 0.75;
		}
		66% {
			opacity: 0.25;
		}
		100% {
			opacity: 0;
		}
	}

	@keyframes fade-to-white {
		from {
			backround-color: black;
		}
		to {
			background-color: white;
		}
	}

	@keyframes stretch {
		from {
			letter-spacing: 0;
			opacity: 1;
		}
		to {
			letter-spacing: calc(100vw / 11);
			opacity: 0.1;
		}
	}
</style>
